import {SET_BRIDGE_TOTAL,SET_BRIDGE, SET_LOADING,SET_BRIDGE_DEVICE,SET_BRIDGE_SUBDATA,SET_BRIDGE_DEVICE_COMM,SET_BRIDGE_DEVICE_COMMFORMAT } from './mutations' 
import axios from 'axios'

export default {
  async fetchBridges({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge/list',
      { params: params },
    )
    commit(SET_BRIDGE, response?.data.value ?? [])
    commit(SET_BRIDGE_SUBDATA, response?.data.subData ?? []);
    commit(SET_BRIDGE_TOTAL, response?.data?.total_count ?? 0)
    
    commit(SET_BRIDGE_DEVICE, response?.data?.device ?? []);
    commit(SET_BRIDGE_DEVICE_COMM, response?.data?.commData ?? []);
    commit(SET_BRIDGE_DEVICE_COMMFORMAT, response?.data?.commFormat ?? []);
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge/get',
      { params: params },
    )
    commit(SET_BRIDGE_SUBDATA, response?.data?.subData ?? [])
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge/add',
      { ...params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge/edit',
      { ...params },
    )
    return response?.data.value || []
  },
  async deleteBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge/delete',
      { ...params },
    )
    return response?.data.value || []
  },
}
