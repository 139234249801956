export const SET_BRIDGE = 'setBridge'
//export const SET_BRIDGE_SUB = ''
export const SET_BRIDGE_TOTAL = 'setBridgeTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'
export const SET_BRIDGE_SUBDATA = 'setBRIDGESubData'
export const SET_BRIDGE_DEVICE = 'setBridgeDevice'
export const SET_BRIDGE_DEVICE_COMM = 'seBridgeDeviceComm'
export const SET_BRIDGE_DEVICE_COMMFORMAT = 'seBridgeDeviceCommFormat'
export default {
  [SET_BRIDGE](state, bridges) {
    state.bridges = bridges
  },
  [SET_BRIDGE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
  [SET_BRIDGE_SUBDATA](state, subData) {
    state.subData = subData
  },
  [SET_BRIDGE_DEVICE](state, device) {
    state.device = device
  },
  [SET_BRIDGE_DEVICE_COMM](state, comm) {
    state.commData = comm
  },
  [SET_BRIDGE_DEVICE_COMMFORMAT](state, commFormat) {
    state.commFormat = commFormat
  },
}
